import { makeStyles } from '@material-ui/core/styles';
import { NextPage } from 'next';

import Button from '@components/common/button';

const useStyles = makeStyles((theme) => ({
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
  },
  code: {
    color: theme.palette.secondary.main,
    fontSize: 120,
    fontWeight: 700,
    lineHeight: 1,
  },
  title: {
    padding: '0 15px',
    marginBottom: '7vh',
    fontWeight: 300,
    fontSize: 18,
    textAlign: 'center',
    color: theme.palette.info.main,
  },
}));

const Custom404Page: NextPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.notFound}>
      <div className={classes.code}>404</div>
      <h1 className={classes.title}>
        The page you were looking for doesn&apos;t exist
      </h1>
      <Button styleType="containedBlue" text="Go to homepage" href="/" />
    </div>
  );
};

export default Custom404Page;
